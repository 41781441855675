<template>
  <div class="faq-page">
    <div class="header fixed z-50 top-0 w-full bg-white">
      <the-header/>
    </div>
    <div class="body pt-40 pb-40">
      <div class="begin w-full flex justify-center items-center">
        <div class="debt flex flex-col justify-center w-3/4">
          <div class="title text-black text-center font-semibold text-4xl">{{ $t('faqTitle') }}</div><br>
          <div class="bloc mt-8">
            <div
              v-for="(item, i) in questionsLists"
              :key="i"
              class="content-bloc"
            >
              <faq-list
                :faq-list="item"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <the-footer/>
    </div>
  </div>
</template>

<script>
import TheHeader from '../../components/the-header'
import TheFooter from '../../components/the-footer'
import FaqList from '../../components/helper/faq/faq-list'
export default {
  name: 'index',
  components: { FaqList, TheFooter, TheHeader },
  data () {
    return {
      questionsLists: [
        {
          title: this.$t('welcomeOnOremi'),
          content: this.$t('welcomeAnswer')
        },
        {
          title: this.$t('whichInsuranceSubscribe'),
          content: this.$t('whichInsuranceAnswer')
        },
        {
          title: this.$t('whichPaymentMethod'),
          content: this.$t('whichPaymentAnswer')
        },
        {
          title: this.$t('howSubscribeInsurance'),
          content: this.$t('howSubscribeAnswer')
        },
        {
          title: this.$t('canModifyContract'),
          content: this.$t('canModifyAnswer')
        },
        {
          title: this.$t('myValidCertificate'),
          content: this.$t('myValidAnswer')
        },
        {
          title: this.$t('howDeclareSinister'),
          content: this.$t('howDeclareAnswer')
        },
        {
          title: this.$t('howGetReimbursed'),
          content: this.$t('howGetAnswer')
        },
        {
          title: this.$t('canDeclareSinister'),
          content: this.$t('canDeclareAnswer')
        },
        {
          title: this.$t('howContactAssistance'),
          content: this.$t('howContactAnswer')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  @media only screen and (max-width: 600px) {
    .body {
      // padding-top: 40rem;
      // padding-bottom: 40rem;
    }
    .debt {
      width: 100%;
    }
  }
  @media only screen and (max-width: 1024px) {
    .body {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
</style>
