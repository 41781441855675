<template>
  <div class="faq-list">
    <div class="faq-list border-b py-4 px-4">
      <div class="debt flex items-center justify-between cursor-pointer" @click="showAnswer">
        <div class="question text-black text-2xl mr-12 leading-relaxed">{{ faqList.title }}</div>
        <div class="icons flex items-center">
          <svg-icon
            name="ic_add-button"
            original
            color="gray"
            class="w-4 h-4"
          />
        </div>
      </div>
      <div
        v-if="!isAnswers"
        class="answer font-hairline text-xl w-11/12 px-4 pt-4 leading-relaxed"
      >
        {{ faqList.content }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'faq-list',
  props: {
    faqList: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      isAnswers: true
    }
  },
  methods: {
    showAnswer () {
      this.isAnswers = !this.isAnswers
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
</style>
